import { request } from './index'

export async function sendQuestion(payload) {
    return request(`support/form/submit/`, 'POST', payload)
}

export async function getGuides() {
    return request(`support/guides/`, 'GET')
}

export async function getGuideDetail(slug) {
    return request(`support/guides/${slug}/`, 'GET')
}
