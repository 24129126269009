// ** Initial State
const initialState = {
    loading: false,
    stages: [],
    applicants: null,
    filteredApplicants: [],
    applicant_statuses: [],
    showingModalStage: false,
    showingInviteModal: false,
    showingMailPopup: false,
    currentStage: null,
    currentApplicant: null,
    selectedApplicants: {},
    filters: {
        selection_stage: null,
        program: null,
        status: null,
        average_score: null,
        country: null,
        page: 1,
        page_size: 10,
        search: '',
        ordering: null
    }
}

const SelectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_STAGE_MODAL': {
            return { ...state, showingModalStage: action.status }
        }
        case 'SET_LOADING': {
            return { ...state, loading: action.loading }
        }
        case 'SHOW_INVITE_MODAL': {
            return {
                ...state,
                showingInviteModal: action.status,
                currentApplicant: action.applicant
            }
        }
        case 'SHOW_MAIL_POPUP': {
            return {
                ...state,
                showingMailPopup: action.status
            }
        }
        case 'SHOW_RIGHT_SIDEBAR': {
            return {
                ...state,
                showingRightSidebar: action.status,
                currentApplicant: action.applicant
            }
        }
        case 'SET_CURRENT_STAGE': {
            return { ...state, currentStage: action.stage }
        }
        case 'SET_SELECTION_FILTERS': {
            return { ...state, filters: action.filters }
        }
        case 'SET_STAGES': {
            return { ...state, stages: action.stages }
        }
        case 'SET_APPLICANTS': {
            return { ...state, applicants: action.applicants, loading: false }
        }
        case 'SET_FILTERED_APPLICANTS': {
            return {
                ...state,
                filteredApplicants: action.filteredApplicants,
                loading: false
            }
        }
        case 'SET_SELECTED_ROWS': {
            return { ...state, selectedApplicants: action.rows }
        }
        case 'SET_APPLICANT_STATUSES': {
            return { ...state, applicant_statuses: action.applicant_statuses }
        }
        case 'REORDER_STAGES': {
            return { ...state, stages: action.elements }
        }
        default:
            return state
    }
}
export default SelectionReducer
