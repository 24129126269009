// ** Initial State
const initialState = {
    documents: null,
    currentDocument: null,
    documentModalShowing: false,
    uploadDocumentModalShowing: false,
    document_types: [],
    memberModalShowing: false
}

const DocumentReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_DOCUMENTS': {
            return { ...state, documents: action.documents }
        }
        case 'SET_CURRENT_DOCUMENT': {
            return { ...state, currentDocument: action.document }
        }
        case 'SET_DOCUMENT_TYPES': {
            return { ...state, document_types: action.types }
        }
        case 'SHOW_DOCUMENT_MODAL': {
            return { ...state, documentModalShowing: action.status }
        }
        case 'SHOW_UPLOAD_DOCUMENT_MODAL': {
            return { ...state, uploadDocumentModalShowing: action.status }
        }
        case 'SHOW_MEMBER_MODAL': {
            return { ...state, memberModalShowing: action.status }
        }

        default:
            return state
    }
}
export default DocumentReducer
