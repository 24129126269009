// ** Initial State
const initialState = {
    stages: null,
    businesses: [],
    investment_statuses: [],
    industries: [],
    tiers: [],
    investment_types: [],
    showingModalStage: false,
    showingInviteModal: false,
    showingMailPopup: false,
    currentStage: null,
    currentDeal: null,
    selectedApplicants: {},
    filters: {
        business_industry: null,
        business_type: null,
        investment_stage: null,
        investment_status: null,
        investment_tier: null,
        investment_round: null,
        search: null
    }
}

const InvestmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_STAGE_MODAL': {
            return { ...state, showingModalStage: action.status }
        }
        case 'SHOW_INVITE_MODAL': {
            return {
                ...state,
                showingInviteModal: action.status,
                currentDeal: action.deal
            }
        }
        case 'SHOW_MAIL_POPUP': {
            return {
                ...state,
                showingMailPopup: action.status
            }
        }
        case 'SHOW_RIGHT_SIDEBAR': {
            return {
                ...state,
                showingRightSidebar: action.status,
                currentDeal: action.deal
            }
        }
        case 'SET_CURRENT_STAGE': {
            return { ...state, currentStage: action.stage }
        }
        case 'SET_CURRENT_DEAL': {
            return { ...state, currentDeal: action.deal }
        }
        case 'SET_SELECTION_FILTERS': {
            return { ...state, filters: action.filters }
        }
        case 'SET_STAGES': {
            return { ...state, stages: action.stages }
        }
        case 'SET_BUSINESSES': {
            return { ...state, businesses: action.businesses }
        }
        case 'SET_SELECTED_ROWS': {
            return { ...state, selectedApplicants: action.rows }
        }
        case 'GET_BUSINESS_INDUSTRIES': {
            return { ...state, industries: action.industries }
        }
        case 'GET_BUSINESS_TYPES': {
            return { ...state, investment_types: action.types }
        }
        case 'GET_BUSINESS_STATUSES': {
            return { ...state, investment_statuses: action.statuses }
        }
        case 'GET_INVESTMENT_TIERS': {
            return { ...state, tiers: action.tiers }
        }
        case 'GET_INVESTMENT_ROUNDS': {
            return { ...state, rounds: action.rounds }
        }

        case 'REORDER_STAGES': {
            return { ...state, stages: action.elements }
        }
        default:
            return state
    }
}
export default InvestmentReducer
