// ** Initial State
const initialState = {
    current_participant: null,
    filters: { page: 1, page_size: 10, business_name: '' }
}

const ParticipantsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CURRENT_PARTICIPANT': {
            return { ...state, current_participant: action.participant }
        }
        case 'SET_PROGRAM_BUSINESSES_FILTERS': {
            return {
                ...state,
                filters: action.program_businesses_filters
            }
        }
        default:
            return state
    }
}
export default ParticipantsReducer
