// ** Initial State
const initialState = {
  videoModalShowing: false,
  scriptModalShowing: false,
  resourceModalShowing: false,
  linkModalShowing: false,
  modules: [],
  currentLesson: null,
  lessonTypes: [],
  currentContent: null

}

const courseConstructorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_ADD_VIDEO_MODAL': {
      return {...state, videoModalShowing: action.status} }
    case 'SHOW_ADD_SCRIPT_MODAL': {
      return {...state, scriptModalShowing: action.status} }
    case 'SHOW_ADD_RESOURCE_MODAL': {
      return {...state, resourceModalShowing: action.status} }
    case 'SHOW_ADD_LINK_MODAL': {
      return {...state, linkModalShowing: action.status} }
    case 'SET_CURRENT_LESSON': {
      return {...state, currentLesson: action.currentLesson} }
    case 'SET_CURRENT_CONTENT': {
      return {...state, currentContent: action.currentContent} }
    case 'SET_LESSON_TYPES': {
      return {...state, lessonTypes: action.lessonTypes} }
    case 'SET_MODULES': {
      return {...state, modules: action.modules} }
    default:
      return state
  }
}
export default courseConstructorReducer
