// ** Initial State
const initialState = {
    items: [],
    preview: false,
    name: '',
    description: '',
    has_investment_section: false,
    is_email_field_required: false
}

const formBuilderReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REORDER_ELEMENTS': {
            return { ...state, items: action.elements }
        }
        case 'ADD_ELEMENT': {
            state.items.push(action.element)
            return { ...state }
        }
        case 'SET_ELEMENTS': {
            return { ...state, items: action.elements }
        }
        case 'DELETE_ELEMENT': {
            state.items.splice(action.index, 1)
            return { ...state }
        }
        case 'SET_EDIT_MODE': {
            state.items[action.index].edit = action.mode
            return { ...state }
        }
        case 'HANDLE_CHANGE_LABEL': {
            state.items[action.index].label = action.value
            return { ...state }
        }
        case 'HANDLE_CHANGE_CONTENT': {
            state.items[action.index].content = action.value
            return { ...state }
        }
        case 'HANDLE_CHANGE_OPTIONS': {
            state.items[action.index].optiosn = action.value
            return { ...state }
        }
        case 'HANDLE_CHANGE_DESCRIPTION': {
            state.items[action.index].description = action.value
            return { ...state }
        }
        case 'HANDLE_CHANGE_WEIGHTAGE': {
            state.items[action.index].weightage = action.value
            return { ...state }
        }
        case 'SET_PREVIEW_MODE': {
            state.preview = action.mode
            return { ...state }
        }
        case 'SET_REQUIRED_MODE': {
            state.items[action.index].required = action.mode
            return { ...state }
        }
        case 'SET_FORM_INFO': {
            state.name = action?.name
            state.description = action?.description
            state.has_investment_section = !!action?.has_investment_section
            state.is_email_field_required = !!action?.is_email_field_required

            return { ...state }
        }
        default:
            return state
    }
}
export default formBuilderReducer
