// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import action_modals from './modals'
import course_detail from '../../views/education/courses/store/reducer'
import forms from '../../../src/views/forms/store/reducer'
import chat from '../../../src/views/chat/store/reducer'

import news from '../../../src/views/announcements/store/reducer'
import education from '../../../src/views/education/store/reducer'
import selections from '../../../src/views/selections/store/reducer'
import deal_flow from '../../views/deal-flow/store/reducer'
import calendar from '../../../src/views/calendar/store/reducer'
import directories from '../../../src/views/directories/store/reducer'

import forms_constructor from '../../../src/views/components/constructor/store/reducer'
import organizations from '../../../src/views/administration/organizations/store/reducer'
import branch_details from '../../../src/views/administration/branch-details/store/reducer'
import branches from '../../../src/views/administration/branches-of-organizations/store/reducer'
import course_constructor from '../../../src/views/education/courses/courses-builder/store/reducer'
import roles_permissions from '../../../src/views/administration/roles-and-permissions/store/reducer'
import participants from '../../../src/views/education/courses/program-courses/participants/store/reducer'
import documents from '../../../src/views/profiles/business-profile/store/reducer'
import mentors from '../../../src/views/components/assign-mentors-modal/store/reducer'
import investment_forms from '../../../src/views/investment-forms/store/reducer'
const rootReducer = combineReducers({
    auth,
    news,
    chat,
    forms,
    navbar,
    layout,
    mentors,
    branches,
    calendar,
    documents,
    education,
    deal_flow,
    selections,
    directories,
    participants,
    course_detail,
    organizations,
    action_modals,
    branch_details,
    investment_forms,
    roles_permissions,
    forms_constructor,
    course_constructor
})

export default rootReducer
