// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { CookiesProvider } from 'react-cookie'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability'
import { ToastContainer } from 'react-toastify'
import { AbilityContext } from './utility/context/Can'
import { ThemeContext } from './utility/context/ThemeColors'
import { IntlProviderWrapper } from './utility/context/Internationalization'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import HelpMessage from './@core/components/help-message'

import { QueryClient, QueryClientProvider } from 'react-query'
import * as Sentry from '@sentry/react'
import { Replay } from '@sentry/react'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchInterval: false
        }
    }
})

if (process.env.REACT_APP_ENV === 'production') {
    console.log('sentry')
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        beforeSend(event, hint) {
            const error = hint.originalException
            if (error && error?.status === 401) {
                return null
            }
            return event
        },
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                // tracePropagationTargets: ['axios', 'fetch']
                tracePropagationTargets: [
                    'localhost',
                    /^https:\/\/www.virtualaccelerate\.com\/api/
                ]
            }),
            new Replay({
                networkDetailAllowUrls: [window.location.origin, '']
            })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

fetch('/asset-manifest.json')
    .then((response) => response.json())
    .then((manifest) => {
        // Сохраните manifest в состоянии или контексте
        console.log('Manifest loaded:', manifest)
    })
// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

if (
    process.env.REACT_APP_ENV === 'staging' ||
    process.env.REACT_APP_ENV === 'production'
) {
    console.error = () => {}
    console.warn = () => {}
}

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <Suspense fallback={<Spinner />}>
                <AbilityContext.Provider value={ability}>
                    <CookiesProvider>
                        <ThemeContext>
                            <IntlProviderWrapper>
                                <LazyApp />
                                <ToastContainer newestOnTop />
                                <HelpMessage />
                            </IntlProviderWrapper>
                        </ThemeContext>
                    </CookiesProvider>
                </AbilityContext.Provider>
            </Suspense>
        </Provider>
    </QueryClientProvider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
