// ** Initial State
const initialState = {
    branchModalShowing: false,
    currentBranch: null,
    branches: null
}

const BranchesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_NEW_BRANCH_MODAL': {
            return { ...state, branchModalShowing: action.status }
        }
        case 'GET_BRANCH_ORGANIZATIONS': {
            return { ...state, branches: action.branches }
        }
        case 'SET_CURRENT_BRANCH': {
            return { ...state, currentBranch: action.branch }
        }
        default:
            return state
    }
}
export default BranchesReducer
