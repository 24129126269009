import { toast } from 'react-toastify'
import { File, FileText, Image, Music, Video } from 'react-feather'
import CryptoJS from 'crypto-js'
import useJwt from '../auth/jwt/useJwt'

import userMessagesEn from '../../src/assets/data/locales/en.json'
import userMessagesSy from '../../src/assets/data/locales/sy.json'
import userMessagesRu from '../../src/assets/data/locales/ru.json'
import userMessagesIr from '../../src/assets/data/locales/ir.json'
import userMessagesPt from '../../src/assets/data/locales/pt.json'

const base64json = require('base64json')

// Функция для шифрования данных
export const encryptData = (data) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString()
}

// Функция для дешифрования данных
export const decryptData = (role_object) => {
    if (!!role_object && typeof role_object === 'string') {
        const secretKey = process.env.REACT_APP_SECRET_KEY
        const bytes = CryptoJS.AES.decrypt(role_object, secretKey)
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    } else {
        const config = useJwt.jwtConfig
        localStorage.removeItem('userData')
        localStorage.removeItem(config.storageTokenKeyName)
        localStorage.removeItem(config.storageRefreshTokenKeyName)
        if (!!role_object && !window.location.href.includes('/login')) {
            window.location.replace('/login')
        }
    }
}

export const isDefaultRole = (role) => {
    const roles = [
        'organization_admin',
        'branch_admin',
        'entrepreneur',
        'mentor',
        'investor'
    ]

    return roles.some((item) => item === role)
}

export const isObjEmpty = (obj) => Object.keys(obj || {}).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => {
    return num > 999 ? `${(num / 1000).toFixed(1)}k` : num
}

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
    const today = new Date()
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
    value,
    formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
    if (!value) return value
    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = { month: 'short', day: 'numeric' }

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' }
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')

export const getUserData = () => {
    const user = JSON.parse(localStorage.getItem('userData'))
    const decrypted_role = decryptData(user?.role)
    if (user) {
        return { ...user, role: decrypted_role }
    } else return null
}

export const getCurrentBranch = () => {
    const user = getUserData()
    if (user) {
        return user?.branch
    }
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
    if (userRole === 'admin') return '/selections/investment-board'
    if (userRole === 'client') return '/access-control'
    return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#13aa4a', // for selected option bg-color
        neutral10: '#13aa4a', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})

/// Get select values from data

export const getDefaultValue = (obj) => {
    return {
        name: obj?.name,
        label: obj?.name,
        ...(obj?.courses && { courses: obj.courses }),
        id: obj?.id
    }
}

export const convertingValues = (values, forCalendar = false) => {
    if (!values || !values.length) {
        return []
    }
    return values?.map((item) => {
        return forCalendar
            ? {
                  label: `${item?.first_name} ${item?.last_name}`,
                  value: `${item?.first_name} ${item?.last_name}`,
                  avatar: item?.photo,
                  id: item?.id
              }
            : {
                  label: item?.name,
                  value: item?.code_name ?? item?.name,
                  id: item?.id
              }
    })
}
export const convertingProgramValues = (values) => {
    if (!values || !values.length) {
        return []
    }
    return values?.map((item) => {
        return {
            label: item?.name,
            value: item?.code_name ?? item?.name,
            courses: item?.courses,
            id: item?.id
        }
    })
}

export const getPermission = (permission) => {
    const user = getUserData()
    if (user) {
        return user?.role[permission]
    }
}

export const getPathPermission = (permissions) => {
    const user = getUserData()
    if (user) {
        return permissions?.every((item) => getPermission(item))
    }
}

export const hasPermissionFn = (
    section,
    actions,
    every = false,
    only = false
) => {
    const permission = getPermission(section)
    let isCorrect = false
    const types = {
        view: 3,
        add: 5,
        edit: 7,
        delete: 11
    }
    if (only) {
        const onlyPermission = permission > 11 ? permission / 3 : permission
        return onlyPermission === types[actions[0]]
    }

    if (every) {
        isCorrect = actions?.every((action) => permission % types[action] === 0)
    } else {
        isCorrect = actions?.some((action) => permission % types[action] === 0)
    }
    return isCorrect
}

export const showByRole = (role_name) => {
    const user = getUserData()
    return role_name === user?.role?.code_name
}
export const showByRoles = (roles_array) => {
    const { role } = getUserData()
    return roles_array.some((name) => name === role.code_name)
}
export const hideByRoles = (roles_array) => {
    const { role } = getUserData()
    return !roles_array.some((name) => name === role.code_name)
}

export const hideByRole = (role_name) => {
    const { role } = getUserData()
    return role_name !== role.code_name
}

export const getRoleCodename = () => {
    const { role } = getUserData()
    return role?.code_name
}

export const hideColumnForInvestment = (investment) => {
    return !!investment
}

export const HasPermission = ({
    children,
    section,
    actions,
    roles,
    every = false,
    only = false
}) => {
    const permission = getPermission(section)
    let isCorrect = false
    const types = {
        view: 3,
        add: 5,
        edit: 7,
        delete: 11
    }
    if (roles && showByRoles(roles)) {
        return <>{children}</>
    }
    if (only) {
        const onlyPermission = permission > 11 ? permission / 3 : permission
        return onlyPermission === types[actions[0]] ? <>{children}</> : null
    }
    if (every) {
        isCorrect = actions?.every((action) => permission % types[action] === 0)
    } else {
        isCorrect = actions?.some((action) => permission % types[action] === 0)
    }

    return isCorrect ? <>{children}</> : null
}

export const toastSuccess = () => {
    const success = t('Changes have been saved successfully')
    toast.success(success)
}
export const toastError = () => {
    const error = t('Something went wrong. Try again later')
    toast.error(error)
}

/**
 ** Generate array of colors by count
 */

export function hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    if (alpha) {
        return `rgb(${r},${g},${b},${alpha})`
    } else {
        return `rgb(${r},${g},${b})`
    }
}

export const generateHexColors = (num = 1, hex = false) => {
    const colors = []
    for (let i = 0; i < num; i++) {
        const letters = '0123456789ABCDEF'.split('')
        let color = '#'
        for (let x = 0; x < 6; x++) {
            color += letters[Math.floor(Math.random() * 16)]
        }
        colors.push(hex ? color : hexToRGB(color, 0.6))
    }
    return colors
}

/**
 ** ##################
 */

/**
 * Convert long number into abbreviated string
 */
export function abbreviateNumber(num) {
    return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 1,
        notation: 'compact',
        compactDisplay: 'short'
    }).format(num)
}

export const getUrlPhoto = (photo) => {
    const base_url = process.env['REACT_APP_BASE_URL']
    if (photo) {
        return `${base_url.slice(0, -1) + photo}`
    }
}

export const getValueFromUrl = (value) => {
    const search = window.location.search
    const params_url = new URLSearchParams(search)
    return params_url.get(value)
}

export const generateZoomAuthLink = (event) => {
    const base_url = process.env['REACT_APP_BASE_URL'].slice(0, -1)

    return `https://zoom.us/oauth/authorize?response_type=code&client_id=${
        process.env.REACT_APP_ZOOM_CLIENT_ID
    }&state=${base64json?.stringify(
        event
    )}&redirect_uri=${base_url}/apps/calendar`
}

export const handleLoginZoom = (data) => {
    const seconds = new Date().getTime() / 1000
    localStorage.setItem(
        'zoomData',
        JSON.stringify({ time_access: seconds + data?.expires_in, ...data })
    )
}

export const isZoomLoggedIn = () => {
    const storage = JSON.parse(localStorage.getItem('zoomData'))

    const seconds = new Date().getTime() / 1000

    if (seconds >= storage?.time_access) {
        localStorage.removeItem('zoomData')
        return false
    }
    return !!(storage && storage?.access_token)
}

export const getZoomToken = () => {
    const storage = JSON.parse(localStorage.getItem('zoomData'))
    if (storage && storage?.access_token) {
        return storage?.access_token
    }
}

export function parse_youtube_id(url) {
    const regExp =
        /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = url.match(regExp)
    console.log('match', match)
    return match && match[2].length === 11 ? match[2] : false
}

// export const t = (id) => {
//     if (!id?.length) return ''
//     const intl = useIntl()
//     return intl.formatMessage({ id })
// }

export const t = (key) => {
    const current_language = window.localStorage.getItem('language') || 'en'
    const languages = {
        en: userMessagesEn,
        ru: userMessagesRu,
        pt: userMessagesPt,
        sy: userMessagesSy,
        ir: userMessagesIr
    }

    return languages[current_language][key] || languages['en'][key] || key
}

export function declension(n, textForms) {
    n = Math.abs(n) % 100
    const n1 = n % 10
    if (n > 10 && n < 20) {
        return textForms[2]
    }
    if (n1 > 1 && n1 < 5) {
        return textForms[1]
    }
    if (n1 === 1) {
        return textForms[0]
    }
    return textForms[2]
}

export const russian_words = {
    lesson: ['Урок', 'Урока', 'Уроков'],
    quiz: ['Контрольный вопрос', 'Контрольных вопроса', 'Контрольных вопросов'],
    module: ['Модуль', 'Модуля', 'Моделей'],
    assignment: ['Назначение', 'Назначения', 'Назначений']
}

export function getFileExtension(url) {
    // Извлекаем последний сегмент URL (после последнего слеша)
    const filename = url.split('/').pop()

    // Извлекаем расширение из имени файла (после последней точки)
    const extension = filename.split('.').pop()

    // Возвращаем расширение или null, если расширение не найдено
    return filename !== extension ? extension : null
}

export function isImageExtension(url) {
    // Извлекаем последний сегмент URL (после последнего слеша)
    const extension_list = ['jpg', 'jpeg', 'png', 'gif', 'bpm']
    const filename = url.split('/').pop()

    // Извлекаем расширение из имени файла (после последней точки)
    const extension = filename.split('.').pop()

    // Возвращаем расширение или null, если расширение не найдено
    return extension_list.some((item) => item === extension)
}

export function getFileIcon(extension) {
    switch (extension.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'bmp':
            return <Image />
        case 'mp4':
        case 'avi':
        case 'mov':
        case 'mkv':
            return <Video />
        case 'mp3':
        case 'wav':
        case 'flac':
            return <Music />
        case 'txt':
        case 'doc':
        case 'docx':
        case 'pdf':
            return <FileText />
        default:
            return <File />
    }
}

export const setPhoneInputErrors = (
    phone,
    options,
    is_required = false,
    setError,
    clearErrors,
    key,
    setValue
) => {
    const length = options.format.split('').filter((l) => l === '.').length
    if (phone === options.dialCode) {
        if (is_required) {
            setError(key, {
                type: 'custom',
                message: 'Required Field'
            })
            setValue && setValue(key, '')
        } else {
            clearErrors(key)
        }
    } else if (phone.length < length && length < 15) {
        setError(key, {
            type: 'custom',
            message: 'Enter a valid number'
        })
        setValue && setValue(key, '')
    } else {
        clearErrors(key)
        setValue && setValue(key, phone)
    }
}
