// ** Initial State
const initialState = {
  organizationModalShowing: false,
  organizations: [],
  currentOrganization: null,
  organization_statuses: []
}

const OrganizationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_ORGANIZATION_MODAL': {
      return {...state, organizationModalShowing: action.status} }
    case 'GET_ORGANIZATIONS': {
      return {...state, organizations: action.organizations} }
    case 'SET_CURRENT_ORGANIZATION': {
      return {...state, currentOrganization: action.organization} }
    case 'SET_ORGANIZATION_STATUSES': {
      return {...state, organization_statuses: action.statuses} }
    default:
      return state
  }
}
export default OrganizationsReducer
