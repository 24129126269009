// ** Initial State
const initialState = {
    course: null,
    module: null,
    lesson: null,
    quiz: null,
    assignment: null
}

const CourseDetailReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_COURSE_DETAIL': {
            return { ...state, course: action.course }
        }
        case 'SET_MODULE_DETAIL': {
            return { ...state, module: action.module }
        }
        case 'SET_LESSON_DETAIL': {
            return { ...state, lesson: action.lesson }
        }
        case 'SET_QUIZ_DETAIL': {
            return { ...state, quiz: action.quiz }
        }
        case 'SET_ASSIGNMENT_DETAIL': {
            return { ...state, assignment: action.assignment }
        }
        default:
            return state
    }
}
export default CourseDetailReducer
