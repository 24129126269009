// ** Initial State

const initialState = {
    news: null,
    showingModalNews: false,
    currentNews: null,
    news_statuses: [],
    news_detail: null,
    news_comments: []
}

const NewsReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_NEWS': {
            return { ...state, news: action.news }
        }
        case 'SHOW_NEWS_MODAL': {
            return { ...state, showingModalNews: action.status }
        }
        case 'SET_NEWS_COMMENTS': {
            return { ...state, news_comments: action.news_comments }
        }
        case 'SET_CURRENT_NEWS': {
            return { ...state, currentNews: action.news }
        }
        case 'SET_NEWS_STATUSES': {
            return { ...state, news_statuses: action.news_statuses }
        }
        case 'SET_NEWS_DETAIL': {
            return { ...state, news_detail: action.news_detail }
        }
        default:
            return state
    }
}
export default NewsReducer
