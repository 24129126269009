// ** Initial State
const initialState = {
    loading: false,
    current_people: null,
    directories: null,
    filtered_directories: [],
    directories_filters: null
}

const DirectoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CURRENT_PEOPLE': {
            return { ...state, current_people: action.current_people }
        }
        case 'SET_DIRECTORIES': {
            return { ...state, directories: action.directories, loading: false }
        }
        case 'SET_LOADING': {
            return { ...state, loading: action.loading }
        }
        case 'SET_FILTERED_DIRECTORIES': {
            return {
                ...state,
                filtered_directories: action.filtered_directories
            }
        }
        case 'SET_DIRECTORIES_FILTERS': {
            return { ...state, directories_filters: action.directories_filters }
        }
        default:
            return state
    }
}
export default DirectoriesReducer
