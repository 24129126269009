import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import { Cookies } from 'react-cookie'
import { instanceOf } from 'prop-types'

export default class JwtService {
    // ** jwtConfig <= Will be used by this service

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }

    jwtConfig = { ...jwtDefaultConfig }

    // ** For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // ** For Refreshing Token
    subscribers = []

    constructor(jwtOverrideConfig) {
        this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

        // ** Request Interceptor
        axios.interceptors.request.use(
            (config) => {
                // ** Get token from localStorage
                const accessToken = this.getToken()

                // ** If token is present add it to request's Authorization Header
                if (accessToken) {
                    // ** eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                }
                return config
            },
            (error) => Promise.reject(error)
        )
        // ** Add request/response interceptor
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                // ** const { config, response: { status } } = error
                const { config, response } = error
                const originalRequest = config
                // ** if (status === 401) {
                if (response && response.status === 401) {
                    if (!this.isAlreadyFetchingAccessToken) {
                        this.isAlreadyFetchingAccessToken = true
                        this.refreshToken()
                            .then((r) => {
                                this.isAlreadyFetchingAccessToken = false
                                // ** Update accessToken in localStorage
                                this.setToken(r.data.tokens.access_token)
                                this.setRefreshToken(
                                    r.data.tokens.refresh_token
                                )
                                this.onAccessTokenFetched(
                                    r.data.tokens.access_token
                                )
                            })
                            .catch((e) => {
                                if (e.response && e.response?.status === 403) {
                                    localStorage.removeItem('accessToken')
                                    localStorage.removeItem('refreshToken')
                                    localStorage.removeItem('userData')
                                    // document.cookies.remove('current_branch')
                                    window.location.href = '/login'
                                }
                            })
                    }
                    return new Promise((resolve) => {
                        this.addSubscriber((accessToken) => {
                            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                            resolve(axios(originalRequest))
                        })
                    })
                }
                return Promise.reject(error)
            }
        )
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter((callback) =>
            callback(accessToken)
        )
    }

    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    getRefreshToken() {
        return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    setRefreshToken(value) {
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    }

    login(...args) {
        return axios.post(this.jwtConfig.loginEndpoint, ...args)
    }

    register(...args) {
        return axios.post(this.jwtConfig.registerEndpoint, ...args)
    }

    getCurrentBranch() {
        const user = JSON.parse(localStorage.getItem('userData'))
        return user?.branch?.id
    }

    refreshToken() {
        const api = 'api/'
        return axios.post(
            this.jwtConfig.baseUrl + api + this.jwtConfig.refreshEndpoint,
            {
                refresh_token: this.getRefreshToken(),
                branch_id: this.getCurrentBranch()
            }
        )
    }
}
