import React from 'react'
import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap'
import { t } from '../../../utility/Utils'
import SubmitButton from '../../../views/components/submit-button'
import { useForm } from 'react-hook-form'
import { sendQuestion } from '../../../api/help-center'
import { toast } from 'react-toastify'

const HelpQuestionsForm = ({ closeModal }) => {
    const { register, handleSubmit, control, errors, reset } = useForm()

    const success_message = t('Thank you, we received your message')
    const error_message = t('Something went wrong. Try again later')
    const onSubmit = async (data) => {
        try {
            await sendQuestion(data)
            toast.success(success_message)
            reset()
            if (closeModal) {
                closeModal()
            }
        } catch (e) {
            toast.error(error_message)
        }
    }
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <h4>{t('Any questions?')}</h4>
            <p>
                {t(
                    "Ask your question about the platform and we'll get back to you shortly."
                )}
            </p>
            <FormGroup>
                <Label for="email">{t('Email')}</Label>
                <Input
                    control={control}
                    type="text"
                    id="email"
                    name="email"
                    placeholder={t('Enter email')}
                    innerRef={register({ required: true })}
                    invalid={errors?.name && true}
                />
                {errors?.name && (
                    <FormFeedback>{t('Required field')}</FormFeedback>
                )}
            </FormGroup>
            <FormGroup>
                <Label for="message">{t('Enter question')}</Label>

                <Input
                    control={control}
                    type="textarea"
                    rows={3}
                    id="message"
                    name="message"
                    placeholder={t('Enter question')}
                    innerRef={register({ required: true })}
                    invalid={errors?.question && true}
                />
                {errors?.question && (
                    <FormFeedback>{t('Required field')}</FormFeedback>
                )}
            </FormGroup>
            <SubmitButton
                type={'button'}
                color={'primary'}
                onClick={handleSubmit(onSubmit)}
            >
                {t('Send')}
            </SubmitButton>
        </Form>
    )
}

export default HelpQuestionsForm
