const initialState = {
    chats: null,
    contacts: [],
    userProfile: {},
    selectedUser: {},
    currentUser: {}
}

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_PROFILE':
            return { ...state, userProfile: action.user }
        case 'GET_USER_CHATS':
            return { ...state, chats: action.data }
        case 'SELECT_CHAT':
            return { ...state, selectedUser: action.user }
        case 'SET_USER':
            return { ...state, currentUser: action.user }
        case 'UPDATE_USER':
            return {
                ...state,
                currentUser: { ...state.currentUser, ...action.data }
            }
        case 'GET_CONTACTS':
            return { ...state, contacts: action.data }
        case 'SEND_MSG':
            state.selectedUser.messages?.unshift(action.message)
            return { ...state }
        default:
            return state
    }
}

export default chatReducer
