const initialState = {
    deleteFn: null,
    confirmFn: null
}

const modalsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DELETE_SOME_THING':
            return { deleteFn: action.deleteFn }

        case 'CONFIRM_SOME_THING':
            return { confirmFn: action.confirmFn }

        default:
            return state
    }
}

export default modalsReducer
