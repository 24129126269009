// ** Initial State
const initialState = {
    investment_application_forms: null,
    form_statuses: [],
    showingModalApplicationForm: false,
    showingShareModal: null,
    currentApplicationForm: null,
    currentScoringForm: null,
    currentChannel: null,
    filters: {
        page: 1,
        page_size: 10,
        search: null,
        form_status: null
    }
}

const InvestmentFormsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_APPLICATION_FORM_MODAL': {
            return { ...state, showingModalApplicationForm: action.status }
        }
        case 'SHOW_SCORING_FORM_MODAL': {
            return { ...state, showingModalScoringForm: action.status }
        }
        case 'SHOW_CHANNEL_FORM_MODAL': {
            return { ...state, showingChannelModal: action.status }
        }
        case 'SHOW_SHARE_MODAL': {
            return { ...state, showingShareModal: action.status }
        }
        case 'SET_CURRENT_APPLICATION_FORM': {
            return { ...state, currentApplicationForm: action.application }
        }
        case 'SET_CURRENT_SCORING_FORM': {
            return { ...state, currentScoringForm: action.scoring }
        }
        case 'SET_CURRENT_CHANNEL': {
            return { ...state, currentChannel: action.channel }
        }
        case 'SET_INVESTMENT_APPLICATION_FORMS': {
            return {
                ...state,
                investment_application_forms:
                    action.investment_application_forms
            }
        }
        case 'SET_SCORING_FORMS': {
            return { ...state, scoring_forms: action.scoring_forms }
        }
        case 'SET_FORM_STATUSES': {
            return { ...state, form_statuses: action.form_statuses }
        }
        case 'GET_CHANNELS': {
            return { ...state, channels: action.channels }
        }
        case 'SET_SELECTION_FILTERS': {
            return { ...state, filters: action.filters }
        }
        default:
            return state
    }
}
export default InvestmentFormsReducer
