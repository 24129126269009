// ** Logo
const SpinnerComponent = ({bg_light, vh = '100'}) => {
  return (
    <div style={{minHeight: `${vh}vh`}} className={`custom-fallback-loader ${bg_light && 'bg-white'}`}>
        <div className='logo-fallback'>
        {/*    <div className='logo-circle' />*/}
        <div className="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        </div>
    </div>
  )
}

export default SpinnerComponent
