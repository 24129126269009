// ** Initial State
const initialState = {
    programs: null,
    showingModalProgram: false,
    allProgramsCount: null,
    currentProgram: null,
    program_courses: null,
    program_statuses: null,
    showingModalProgramStatus: false,
    currentStatus: null,
    courses: null,
    course_participants: null,
    showingModalCourse: false,
    currentCourse: null,
    course_statuses: null
}

const EducationReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_PROGRAMS': {
            return { ...state, programs: action.programs }
        }
        case 'SET_PROGRAMS_COUNT': {
            return { ...state, allProgramsCount: action.programs_count }
        }
        case 'SHOW_PROGRAM_MODAL': {
            return { ...state, showingModalProgram: action.status }
        }
        case 'SET_CURRENT_PROGRAM': {
            return { ...state, currentProgram: action.program }
        }
        case 'SET_PROGRAM_COURSES': {
            return { ...state, program_courses: action.program_courses }
        }


        case 'SET_PROGRAM_STATUSES': {
            return { ...state, program_statuses: action.program_statuses }
        }
        case 'SHOW_PROGRAM_STATUS_MODAL': {
            return { ...state, showingModalProgramStatus: action.status }
        }
        case 'SET_CURRENT_STATUS': {
            return { ...state, currentStatus: action.status }
        }

        case 'SET_COURSES': {
            return { ...state, courses: action.courses }
        }
        case 'SET_COURSE_PARTICIPANTS': {
            return { ...state, course_participants: action.course_participants }
        }
        case 'SHOW_COURSE_MODAL': {
            return { ...state, showingModalCourse: action.status }
        }
        case 'SET_CURRENT_COURSE': {
            return { ...state, currentCourse: action.course }
        }
        case 'SET_COURSE_STATUSES': {
            return { ...state, course_statuses: action.course_statuses }
        }
        default:
            return state
    }
}
export default EducationReducer
