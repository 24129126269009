// ** Initial State
const initialState = {
  roleModalShowing: false,
  currentRole: null
}

const RoleAndPermissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_ROLE_MODAL': {
      return {...state, roleModalShowing: action.status} }
    case 'SET_CURRENT_ROLE': {
      return {...state, currentRole: action.role} }
    case 'SET_ROLES': {
      return {...state, roles: action.roles} }
    default:
      return state
  }
}
export default RoleAndPermissionsReducer
