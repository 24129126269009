import { Button, Spinner } from 'reactstrap'
import { useEffect, useState } from 'react'

const SubmitButton = ({
    innerLoading = false,
    children,
    onClick,
    disabled,
    className,
    ...props
}) => {
    const [loading, setLoading] = useState(false)

    const SubmitFn = () => {
        setLoading(true)
        onClick()?.finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        return () => setLoading(false)
    }, [])

    useEffect(() => {
        setLoading(innerLoading)
    }, [innerLoading])

    return (
        <Button
            onClick={SubmitFn}
            className={`position-relative ${className}`}
            disabled={loading || disabled}
            {...props}
        >
            <span
                className={'user-select-none'}
                style={
                    loading
                        ? { color: 'transparent', background: 'transparent' }
                        : {}
                }
            >
                {children}
            </span>
            {loading && (
                <Spinner
                    color={props.outline ? props.color : 'white'}
                    className={
                        'position-absolute position-top-0 position-left-0 position-right-0 position-bottom-0 m-auto'
                    }
                    size="sm"
                />
            )}
        </Button>
    )
}

export default SubmitButton
