// ** Initial State
const initialState = {
  events: [],
  selectedEvent: {},
  selectedCalendars: ['Public', 'Private'],
  addFeedBackModal: false,
  feedBackListModal: false
}

const calenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_EVENTS':
      return { ...state, events: action.events }
    case 'UPDATE_FILTERS':
      const filterIndex = state.selectedCalendars.findIndex(i => i === action.filter)
      if (state.selectedCalendars.includes(action.filter)) {
        state.selectedCalendars.splice(filterIndex, 1)
      } else {
        state.selectedCalendars.push(action.filter)
      }
      if (!state.selectedCalendars.length) {
        state.events.length = 0
      }
      return { ...state }
    case 'UPDATE_ALL_FILTERS':
      // ** Updates All Filters based on action value
      let selected = []
      if (action.value) {
        selected = ['Public', 'Private']
      } else {
        selected = []
      }
      return { ...state, selectedCalendars: selected }

    case 'SELECT_EVENT':
      return { ...state, selectedEvent: action.event }

    case 'SHOW_FEEDBACK_MODAL':
      return { ...state, addFeedBackModal: action.status }

    case 'SHOW_FEEDBACK_LIST_MODAL':
      return { ...state, feedBackListModal: action.status }

    default:
      return state
  }
}

export default calenderReducer
