// ** Initial State
const initialState = {
    currentBusiness: null,
    mentors: null
}

const MentorReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CURRENT_BUSINESS': {
            return { ...state, currentBusiness: action.business }
        }
        case 'SET_MENTORS': {
            return { ...state, mentors: action.mentors }
        }
        default:
            return state
    }
}
export default MentorReducer
