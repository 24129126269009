import React, { useState } from 'react'
import { HelpCircle, XCircle } from 'react-feather'
import './help-message.scss'
import { Card, CardBody } from 'reactstrap'
import HelpQuestionsForm from '../help-message-form'

const banned_routes = [
    '/auth',
    '/register',
    '/login',
    '/forgot-password',
    '/application-forms'
]

const HelpMessage = () => {
    const [isOpen, setIsOpen] = useState(false)

    const isHidden = banned_routes.some((route) =>
        window.location.href.includes(route)
    )

    return isHidden ? null : (
        <div className={'help-message_container'}>
            <Card
                className={'help-message'}
                style={
                    isOpen
                        ? {
                              transform: `translate3d(0, 0, 0)`
                          }
                        : {}
                }
            >
                <CardBody>
                    <HelpQuestionsForm />
                </CardBody>
            </Card>
            <div
                className="cursor-pointer bg-success d-flex align-items-center justify-content-center"
                style={{
                    width: 50,
                    height: 50,
                    borderRadius: '50%',
                    boxShadow: '-1px 4px 23px 0px rgba(34, 60, 80, 0.2)'
                }}
                onClick={() => setIsOpen(!isOpen)}
            >
                {isOpen ? (
                    <XCircle size={30} className={'text-white'} />
                ) : (
                    <HelpCircle size={30} className={'text-white'} />
                )}
            </div>
        </div>
    )
}

export default HelpMessage
