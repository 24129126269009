// ** Initial State
const initialState = {
  adminModalShowing: false,
  users: []
}

const BranchDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_NEW_ADMIN_MODAL': {
      return {...state, adminModalShowing: action.status} }
    case 'GET_ORGANIZATION_USERS': {
      return {...state, users: action.users} }
    default:
      return state
  }
}
export default BranchDetailsReducer
