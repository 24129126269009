// ** Auth Endpoints
export default {
  loginEndpoint: '/login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: 'auth/refresh-access-token/',
  logoutEndpoint: '/jwt/logout',
  baseUrl: process.env['REACT_APP_BASE_URL'],

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
